import styled from 'styled-components';

const Container = styled.div`
  margin: 0 auto;
  max-width: 1000px;
  text-align: left;
`;

const Title = styled.p`
  font-size: 24px;
  font-weight: bold;
  text-align: center;
`;

const Bold = styled.p`
  font-size: 16px;
  font-weight: bold;
`;

const P = styled.p`
  font-size: 14px;
  line-height: 1.5;
`;

const Index = () => {
  return (
    <Container>
      <Title>Knowledge Base and FAQ</Title>
      <Bold>What do we detect?</Bold>
      <P>
        For Web3.0 users, various forms of fraud are prevalent, with honeypot
        assets being one of the most common. Within honeypot assets, attackers
        employ malicious code or rug-pull functionality to deceive users,
        resulting in financial losses. Goplus Assets Health Detection leverages
        the Goplus Security engine to detect a wide range of backdoors,
        assisting users in mitigating risks.
      </P>

      <Bold>How to evaluate the result?</Bold>
      <P>
        Goplus Assets Health Detection examines all valid assets in a user's
        wallet, conducting comprehensive analysis based on contract source code
        and behavioral attributes. It aggregates various risks, providing risk
        alerts and warnings based on severity levels.
      </P>

      <Bold>What networks we support?</Bold>
      <P>
        Currently, we only support the Ethereum mainnet, but we will gradually
        expand our support to include other networks.
      </P>

      <Bold>What are the next steps after result?</Bold>
      <P>
        Please exercise caution when dealing with assets that carry high-risk
        alerts. If you require further information or wish to take action on
        assets marked as risky, you can visit
        https://gopluslabs.io/token-security/ to access detailed reports for the
        corresponding assets. It's crucial to treat risk alerts seriously and
        proceed with actions only after understanding their implications.
      </P>

      <Bold>
        How to get more detailed result of the specific token appears in your
        result?
      </Bold>
      <P>
        You can visit https://gopluslabs.io/token-security/ to access detailed
        reports for the corresponding assets.
      </P>

      <Bold>
        What are the follow-up plans for Goplus Assets Health Detection Snaps?
      </Bold>
      <P>
        We will continue to update Goplus Assets Health Detection Snaps in line
        with updates to the Snaps interface and specifications, enhancing its
        usability over time. Currently, as a Beta version, we only support Token
        asset detection on the Ethereum mainnet. In the future, we plan to
        support a broader range of risk detection types, including approval
        safety checks and NFT risk detection. And we also will expand our
        services to more networks.
      </P>
    </Container>
  );
};

export default Index;
